<script setup lang="ts">
import { onMounted, ref } from "vue";

import type { Bill } from "@/interfaces/bill.interface";

import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import { useClientStore } from "@/stores/clientStore";
import { useRouter } from "vue-router";

import BillList from "@/components/dashboard/bill/BillList.vue";
import { fetchBills } from "@/services/bill.services";

const router = useRouter();
const clientStore = useClientStore();
const clientTheme = clientStore.theme;

const billsError = ref(false);
const pendingBills = ref<Bill[]>([]);
const paidBills = ref<Bill[]>([]);

onMounted(async () => {
  await loadBills();
});

async function loadBills() {
  try {
    const response = await fetchBills();
    const data = response.data;
    if (Array.isArray(data.results)) {
      pendingBills.value = data.results.filter(
        (bill: Bill) => bill.estado === 2
      );
      paidBills.value = data.results.filter((bill: Bill) => bill.estado === 3);
    } else {
      throw new Error("No results found in the response");
    }
    billsError.value = false;
  } catch (error) {
    console.log(error);
    billsError.value = true;
  }
}
</script>
<template>
  <div
    class="min-h-full flex-col flex-grow justify-start py-12 px-5 md:px-24 gap-4"
    :class="clientTheme"
  >
    <header
      class="flex justify-between items-center mb-5 md:mb-8 mt-1 relative"
    >
      <button
        @click="router.push({ name: 'Inicio' })"
        class="flex btn-transition items-center text-grayDetails-100"
      >
        <ChevronLeftIcon class="mr-1 text-grayDetails-100" />
        <span class="text-primary font-montserrat text-skin-major"
          >Facturas</span
        >
      </button>
    </header>

    <main class="flex flex-col gap-y-3 md:gap-y-5">
      <BillList
        title="Pendiente de pago"
        :bills="pendingBills"
        :showEmptyAlert="true"
        :showErrorAlert="billsError"
      />
      <div v-if="paidBills.length">
        <BillList
          title="Pagadas"
          :bills="paidBills"
          :showEmptyAlert="false"
          :showErrorAlert="billsError"
        />
      </div>
    </main>
  </div>
</template>

<style scoped></style>
