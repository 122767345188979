import type { BillDetail } from "@/interfaces/bill.interface";
import { axiosInstance as axios } from "./axios.config";

const PAGOTIC = import.meta.env.VITE_PAGOTIC_API_URL;

export const fetchBills = async (): Promise<{ data: any }> => {
  return axios.get("/invoice_list/");
};

export const fetchBillDetail = async (
  id: number
): Promise<{ data: BillDetail }> => {
  return axios.get(`/invoice_detail/${id}/`);
};

export const creatNewBillRequest = async (billId: any): Promise<any> => {
  return await axios.post(`${PAGOTIC}/retry/${billId}/`, {});
};
